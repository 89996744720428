import {
  GET_SELECT_REGIONS,
  MY_REGIONS,
  UPDATE_MY_REGIONS,
} from '../actionType';

const INIT_STATE = {
  regions: false,
  regions_select: false,
  loading: true,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SELECT_REGIONS:
      return {
        ...state,
        regions_select: action.payload,
        loading: false,
      };
    case UPDATE_MY_REGIONS:
      return {
        ...state,
        loading: false,
      };
    case MY_REGIONS:
      return {
        ...state,
        regions: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    default:
      return state;
  }
};
