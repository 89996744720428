/**
 * SKILLS
 * @type {string}
 */
export const GET_SKILL = 'GET_SKILL';
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const GET_SELECT_SKILLS = 'GET_SELECT_SKILLS';
export const INSERT_SKILL = 'INSERT_SKILL';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const DELETE_SKILL = 'DELETE_SKILL';
export const INSTANCE_SKILL = 'INSTANCE_SKILL';
export const CLEAR_SKILLS = 'CLEAR_SKILLS';
export const INSERT_MY_SKILL = 'INSERT_MY_SKILL';
export const DELETE_MY_SKILL = 'DELETE_MY_SKILL';
export const MY_SKILLS = 'MY_SKILLS';

/**
 * JOBS
 * @type {string}
 */
export const GET_JOB = 'GET_JOB';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const GET_SELECT_JOBS = 'GET_SELECT_JOBS';
export const INSERT_JOB = 'INSERT_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const INSTANCE_JOB = 'INSTANCE_JOB';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const INSERT_MY_JOB = 'INSERT_MY_JOB';
export const DELETE_MY_JOB = 'DELETE_MY_JOB';
export const UPDATE_MY_JOB = 'UPDATE_MY_JOB';
export const MY_JOBS = 'MY_JOBS';

/**
 * EDUCATIONS
 * @type {string}
 */
export const CLEAR_EDUCATIONS = 'CLEAR_EDUCATIONS';
export const INSERT_MY_EDUCATION = 'INSERT_MY_EDUCATION';
export const DELETE_MY_EDUCATION = 'DELETE_MY_EDUCATION';
export const UPDATE_MY_EDUCATION = 'UPDATE_MY_EDUCATION';
export const MY_EDUCATIONS = 'MY_EDUCATIONS';

/**
 * LANGUAGES
 * @type {string}
 */
export const GET_SELECT_LANGUAGES = 'GET_SELECT_LANGUAGES';
export const MY_LANGUAGES = 'MY_LANGUAGES';
export const UPDATE_MY_LANGUAGES = 'UPDATE_MY_LANGUAGES';
export const DELETE_MY_LANGUAGES = 'DELETE_MY_LANGUAGES';
export const INSERT_MY_LANGUAGES = 'DELETE_MY_LANGUAGES';

/**
 * REGIONS
 * @type {string}
 */

export const GET_SELECT_REGIONS = 'GET_SELECT_REGIONS';
export const MY_REGIONS = 'MY_REGIONS';
export const UPDATE_MY_REGIONS = 'UPDATE_MY_REGIONS';

/**
 * CITIES
 * @type {string}
 */
export const GET_SELECT_CITIES = 'GET_SELECT_CITIES';
export const MY_CITIES = 'MY_CITIES';
export const UPDATE_MY_CITIES = 'UPDATE_MY_CITIES';

/**
 * JOBSECTORS
 * @type {string}
 */
export const GET_SELECT_JOB_SECTORS = 'GET_SELECT_JOB_SECTORS';
export const UPDATE_SECTORS = 'UPDATE_SECTORS';

/**
 * SCHOOL
 * @type {string}
 */
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS';
export const GET_SELECT_SCHOOLS = 'GET_SELECT_SCHOOLS';
export const INSERT_SCHOOL = 'INSERT_SCHOOL';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const INSTANCE_SCHOOL = 'INSTANCE_SCHOOL';
export const CLEAR_SCHOOLS = 'CLEAR_SCHOOLS';

/**
 * STUDY_COURSE
 * @type {string}
 */
export const GET_STUDY_COURSE = 'GET_STUDY_COURSE';
export const GET_ALL_STUDY_COURSES = 'GET_ALL_STUDY_COURSES';
export const GET_SELECT_STUDY_COURSES = 'GET_SELECT_STUDY_COURSES';
export const INSERT_STUDY_COURSE = 'INSERT_STUDY_COURSE';
export const UPDATE_STUDY_COURSE = 'UPDATE_STUDY_COURSE';
export const DELETE_STUDY_COURSE = 'DELETE_STUDY_COURSE';
export const INSTANCE_STUDY_COURSE = 'INSTANCE_STUDY_COURSE';
export const CLEAR_STUDY_COURSES = 'CLEAR_STUDY_COURSES';

/**
 * EDUCATIONAL_QUALIFICATION
 * @type {string}
 */
export const GET_EDUCATIONAL_QUALIFICATION = 'GET_EDUCATIONAL_QUALIFICATION';
export const GET_ALL_EDUCATIONAL_QUALIFICATIONS =
  'GET_ALL_EDUCATIONAL_QUALIFICATIONS';
export const GET_SELECT_EDUCATIONAL_QUALIFICATIONS =
  'GET_SELECT_EDUCATIONAL_QUALIFICATIONS';
export const INSERT_EDUCATIONAL_QUALIFICATION =
  'INSERT_EDUCATIONAL_QUALIFICATION';
export const UPDATE_EDUCATIONAL_QUALIFICATION =
  'UPDATE_EDUCATIONAL_QUALIFICATION';
export const DELETE_EDUCATIONAL_QUALIFICATION =
  'DELETE_EDUCATIONAL_QUALIFICATION';
export const INSTANCE_EDUCATIONAL_QUALIFICATION =
  'INSTANCE_EDUCATIONAL_QUALIFICATION';
export const CLEAR_EDUCATIONAL_QUALIFICATIONS =
  'CLEAR_EDUCATIONAL_QUALIFICATIONS';
export const GET_SELECT_CLIENTS = 'GET_SELECT_CLIENTS';

/**
 * STATISTICS
 * @type {string}
 */
export const GET_MASTERSTAT = 'GET_MASTERSTAT';

export const GET_STATISTICS = 'GET_STATISTICS';

/**
 * COUNTRY
 */
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const GET_SELECT_COUNTRY = 'GET_SELECT_COUNTRY';
export const INSTANCE_COUNTRY = 'INSTANCE_COUNTRY';

/**
 * SELECTION
 */
export const INSERT_SELECTION = 'INSERT_SELECTION';
export const GET_ALL_SELECTION = 'GET_ALL_SELECTION';
export const INSTANCE_SELECTION = 'INSTANCE_SELECTION';
export const MAIL_SELECTION = 'MAIL_SELECTION';
export const REMOVE_USER_SELECTION = 'REMOVE_USER_SELECTION';

/**
 * USERS
 */
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SELECTION_NOTE = 'UPDATE_USER_SELECTION_NOTE';
export const UPDATE_USER_SELECTION_STATUS = 'UPDATE_USER_SELECTION_STATUS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_VENDORS = 'GET_VENDORS';
export const GET_COMPANY_CLIENTS = 'GET_COMPANY_CLIENTS';
export const ACCOUNT = 'ACCOUNT';

/**
 * REGISTRIES
 */
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';
export const GET_ALL_COMPANY_CLIENTS = 'GET_ALL_COMPANY_CLIENTS';
export const VENDOR_INSTANCE = 'VENDOR_INSTANCE';
export const CLIENT_INSTANCE = 'CLIENT_INSTANCE';
export const INSERT_VENDOR = 'INSERT_VENDOR';
export const INSERT_CLIENT = 'INSERT_CLIENT';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const CLIENT_COMPANY_INSTANCE = 'CLIENT_COMPANY_INSTANCE';
export const CLEAR_REGISTRIES = 'CLEAR_REGISTRIES';

/**
 * REGISTRIES_ADDRESS
 */
export const GET_ALL_REGISTRY_ADDRESSES = 'GET_ALL_REGISTRY_ADDRESSES';
export const INSTANCE_REGISTRY_ADDRESS = 'INSTANCE_REGISTRY_ADDRESS';
export const INSERT_REGISTRY_ADDRESS = 'INSERT_REGISTRY_ADDRESS';
export const UPDATE_REGISTRY_ADDRESS = 'UPDATE_REGISTRY_ADDRESS';
export const DELETE_REGISTRY_ADDRESS = 'DELETE_REGISTRY_ADDRESS';

/**
 * NOTICATIONS
 */
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_HEADER_NOTIFICATIONS = 'GET_ALL_HEADER_NOTIFICATIONS';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SET_NOTIFICATION_READ = 'SET_NOTIFICATION_READ';
export const SET_NOTIFICATION_NOT_READ = 'SET_NOTIFICATION_NOT_READ';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export * from './menu/actions';
export * from './settings/actions';
