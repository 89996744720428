import {
  DELETE_NOTIFICATION,
  GET_ALL_HEADER_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATION,
  SET_NOTIFICATION_NOT_READ,
  SET_NOTIFICATION_READ,
  CLEAR_NOTIFICATIONS,
} from '../actionType';

const INIT_STATE = {
  notifications: false,
  notifications_header: false,
  notification: false,
  total_count: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case GET_ALL_HEADER_NOTIFICATIONS:
      return {
        ...state,
        notifications_header: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };
    case SET_NOTIFICATION_READ:
      return {
        ...state,
        loading: false,
      };
    case SET_NOTIFICATION_NOT_READ:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: false,
        loading: false,
      };
    default:
      return state;
  }
};
