import { GET_SELECT_CITIES, MY_CITIES, UPDATE_MY_CITIES } from '../actionType';

const INIT_STATE = {
  cities: false,
  cities_select: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SELECT_CITIES:
      return {
        ...state,
        cities_select: action.payload,
        loading: false,
      };
    case UPDATE_MY_CITIES:
      return {
        ...state,
        loading: false,
      };
    case MY_CITIES:
      return {
        ...state,
        cities: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    default:
      return state;
  }
};
