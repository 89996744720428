/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'it';
export const localeOptions = [
  { id: 'it', name: 'Italiano', direction: 'ltr' },
  { id: 'en', name: 'English', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

// eslint-disable-next-line no-unused-vars
const JobSectors = [
  { id: '3', name: 'Agricoltura e allevamento' },
  { id: '4', name: 'Ambiente e tutela del territorio' },
  { id: '5', name: 'Amministrazione, finanza e controllo d’impresa' },
  { id: '6', name: 'Artigianato artistico' },
  { id: '7', name: 'Attività commerciale' },
  { id: '8', name: 'Audiovisivi e spettacolo' },
  { id: '9', name: 'Beni culturali' },
  { id: '10', name: 'Chimica' },
  { id: '11', name: 'Commerciale e marketing' },
  { id: '12', name: 'Comunicazione e giornalismo' },
  { id: '13', name: 'Edilizia e urbanistica' },
  { id: '14', name: 'Educazione e formazione' },
  { id: '15', name: 'Forze armate e sicurezza' },
  { id: '16', name: 'Gestione risorse umane' },
  { id: '17', name: 'Grafica ed editoria' },
  { id: '18', name: 'Grande distribuzione' },
  { id: '19', name: 'Industria agroalimentare' },
  { id: '20', name: 'Informatica ed elettronica' },
  { id: '21', name: 'Metalmeccanica' },
  { id: '22', name: 'Moda e abbigliamento' },
  { id: '23', name: 'Non profit' },
  { id: '24', name: 'Pubblica amministrazione' },
  { id: '25', name: 'Pubblicità' },
  { id: '26', name: 'Ristorazione e alimentazione' },
  { id: '27', name: 'Servizi finanziari e assicurativi' },
  { id: '28', name: 'Servizi sanitari' },
  { id: '29', name: 'Servizi sociali' },
  { id: '30', name: 'Sport' },
  { id: '31', name: 'Telecomunicazioni' },
  { id: '32', name: 'Trasporti' },
  { id: '33', name: 'Turismo, ospitalità e tempo libero' },
];

export const currentUser = {
  id: undefined,
  title: undefined,
  img: undefined,
  date: undefined,
  role: undefined,
};

export const adminRoot = '/';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
