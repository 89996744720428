import { GET_SELECT_JOB_SECTORS, UPDATE_MY_REGIONS } from '../actionType';

const INIT_STATE = {
  jobs_sectors: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SELECT_JOB_SECTORS:
      return {
        ...state,
        jobs_sectors: action.payload,
        loading: false,
      };
    case UPDATE_MY_REGIONS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
