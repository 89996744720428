import { GET_STATISTICS } from '../actionType';

const INIT_STATE = {
  dashboard: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATISTICS:
      return {
        ...state,
        dashboard: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
