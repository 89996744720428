import {
  GET_ALL_CLIENTS,
  GET_ALL_COMPANY_CLIENTS,
  GET_ALL_VENDORS,
  VENDOR_INSTANCE,
  INSERT_VENDOR,
  UPDATE_VENDOR,
  DELETE_VENDOR,
  CLIENT_COMPANY_INSTANCE,
  CLIENT_INSTANCE,
  INSERT_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  CLEAR_REGISTRIES,
  GET_SELECT_CLIENTS,
} from '../actionType';

const INIT_STATE = {
  vendors: false,
  clients: false,
  company_clients: false,
  vendor: false,
  company_client: false,
  client_user: false,
  clients_users: false,
  company_select: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_VENDORS:
      return {
        ...state,
        vendors: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSERT_VENDOR:
      return {
        ...state,
        vendors: [action.payload].concat(state.vendors),
        loading: false,
      };
    case VENDOR_INSTANCE:
      return {
        ...state,
        vendor: action.payload,
        loading: false,
      };
    case UPDATE_VENDOR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_VENDOR:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_COMPANY_CLIENTS:
      return {
        ...state,
        company_clients: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case CLIENT_COMPANY_INSTANCE:
      return {
        ...state,
        company_client: action.payload,
        loading: false,
      };
    case INSERT_CLIENT:
      return {
        ...state,
        clients: [action.payload].concat(state.clients),
        loading: false,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_CLIENTS:
      return {
        ...state,
        clients_users: action.payload,
        loading: false,
      };
    case CLIENT_INSTANCE:
      return {
        ...state,
        client_user: action.payload,
        loading: false,
      };
    case GET_SELECT_CLIENTS:
      return {
        ...state,
        company_select: action.payload,
        loading: false,
      };
    case CLEAR_REGISTRIES:
      return {
        ...state,
        clients_users: false,
        company_clients: false,
        vendors: false,
        loading: false,
      };
    default:
      return state;
  }
};
