import axios from 'axios';
import { getStorage } from '_helpers/LocalStorageHelpers';

export const LOGIN_URL = `${process.env.REACT_APP_HOST_NAME}/authenticate`;
export const REGISTER_URL = `${process.env.REACT_APP_HOST_NAME}/register`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_HOST_NAME}/forgot_pass`;
export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_HOST_NAME}/change_passforget`;
export const REGISTER_CONFIRM_URL = `${process.env.REACT_APP_HOST_NAME}/registration_confirm`;
export const SELECTION_CONFIRM_URL = `${process.env.REACT_APP_HOST_NAME}/selection_confirmation`;
export const PASSWORD_FORGET_URL = `${process.env.REACT_APP_HOST_NAME}/registration_confirm`;
export const VERIFICATION_CONFIRM_URL = `${process.env.REACT_APP_HOST_NAME}/verification`;
export const ME_URL = `${process.env.REACT_APP_HOST_NAME}/me`;

export function loginUser(login, pass, token) {
  return axios.post(LOGIN_URL, { login, pass, token });
}
export function logout() {
  return false;
}

export function register(values) {
  return axios.post(REGISTER_URL, values);
}

export function registerConfirm(tkn, usr, token) {
  return axios.post(REGISTER_CONFIRM_URL, { tkn, usr, token });
}

export function selectionConfirmation(tkn, usr, token, selectionId) {
  return axios.post(SELECTION_CONFIRM_URL, { tkn, usr, token, selectionId });
}

export function passwordForget(tkn, usr) {
  return axios.post(PASSWORD_FORGET_URL, { tkn, usr });
}

export function otpVerification(tkn, tknId, otp) {
  return axios.post(VERIFICATION_CONFIRM_URL, {
    tkn,
    tknId,
    otp,
  });
}

export function requestPassword(email, token) {
  return axios.post(REQUEST_PASSWORD_URL, { email, token });
}

export function resetPassword(newPassword, token, usr, tkn) {
  return axios.post(CHANGE_PASSWORD_URL, { newPassword, token, usr, tkn });
}
export function getUserByToken() {
  return axios.post(ME_URL, { tkn_id: getStorage('tkn_id') });
}
