import {
  GET_USER,
  ACCOUNT,
  DELETE_USER,
  UPDATE_USER,
  GET_CLIENTS,
  GET_VENDORS,
  GET_USERS,
  UPDATE_USER_SELECTION_NOTE,
  UPDATE_USER_SELECTION_STATUS,
} from '../actionType';

const INIT_STATE = {
  users: false,
  user: false,
  clients: false,
  vendors: false,
  account: false,
  total_count: 0,
  loading: true,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case ACCOUNT:
      return {
        ...state,
        account: action.payload,
        loading: false,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case GET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_SELECTION_NOTE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_SELECTION_STATUS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
