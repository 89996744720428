import {
  GET_ALL_COUNTRY,
  GET_SELECT_COUNTRY,
  INSTANCE_COUNTRY,
} from '../actionType';

const INIT_STATE = {
  countries_select: [],
  countries: [],
  country: [],
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRY:
      return {
        ...state,
        countries: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSTANCE_COUNTRY:
      return {
        ...state,
        country: action.payload,
        loading: false,
      };
    case GET_SELECT_COUNTRY:
      return {
        ...state,
        countries_select: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
