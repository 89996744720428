import {
  MY_EDUCATIONS,
  INSERT_MY_EDUCATION,
  UPDATE_MY_EDUCATION,
  DELETE_MY_EDUCATION,
  CLEAR_EDUCATIONS,
  GET_ALL_SCHOOLS,
  INSERT_SCHOOL,
  UPDATE_SCHOOL,
  DELETE_SCHOOL,
  CLEAR_SCHOOLS,
  CLEAR_STUDY_COURSES,
  DELETE_STUDY_COURSE,
  UPDATE_STUDY_COURSE,
  INSERT_STUDY_COURSE,
  GET_ALL_STUDY_COURSES,
  GET_SELECT_STUDY_COURSES,
  GET_SELECT_SCHOOLS,
  GET_ALL_EDUCATIONAL_QUALIFICATIONS,
  GET_SELECT_EDUCATIONAL_QUALIFICATIONS,
  INSERT_EDUCATIONAL_QUALIFICATION,
  UPDATE_EDUCATIONAL_QUALIFICATION,
  DELETE_EDUCATIONAL_QUALIFICATION,
  CLEAR_EDUCATIONAL_QUALIFICATIONS,
  GET_SELECT_LANGUAGES,
  MY_LANGUAGES,
  DELETE_MY_LANGUAGES,
  UPDATE_MY_LANGUAGES,
  INSERT_MY_LANGUAGES,
} from '../actionType';

const INIT_STATE = {
  educations: false,
  schools_select: false,
  schools: false,
  school: false,
  educational_qualification_select: false,
  educational_qualifications: false,
  educational_qualification: false,
  study_courses_select: false,
  study_courses: false,
  study_course: false,
  education: false,
  language: false,
  my_languages: false,
  languages: false,
  languages_select: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    /**
     * EDUCATIONS
     */
    case MY_EDUCATIONS:
      return {
        ...state,
        educations: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSERT_MY_EDUCATION:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MY_EDUCATION:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MY_EDUCATION:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_EDUCATIONS:
      return {
        ...state,
        educations: false,
        loading: false,
      };

    /**
     * SCHOOLS
     */

    case GET_ALL_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
        total_count: action.total_count,
        loading: false,
      };

    case GET_SELECT_SCHOOLS:
      return {
        ...state,
        schools_select: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSERT_SCHOOL:
      return {
        ...state,
        schools: [action.payload].concat(state.schools),
        loading: false,
      };
    case UPDATE_SCHOOL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SCHOOL:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_SCHOOLS:
      return {
        ...state,
        schools: false,
        loading: false,
      };

    /**
     * STUDY COURSES
     */
    case GET_ALL_STUDY_COURSES:
      return {
        ...state,
        study_courses: action.payload,
        total_count: action.total_count,
        loading: false,
      };

    case GET_SELECT_STUDY_COURSES:
      return {
        ...state,
        study_courses_select: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSERT_STUDY_COURSE:
      return {
        ...state,
        study_courses: [action.payload].concat(state.study_courses),
        loading: false,
      };
    case UPDATE_STUDY_COURSE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_STUDY_COURSE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_STUDY_COURSES:
      return {
        ...state,
        study_courses: false,
        loading: false,
      };

    /**
     * EDUCATIONAL QUALIFICATION
     */

    case GET_ALL_EDUCATIONAL_QUALIFICATIONS:
      return {
        ...state,
        educational_qualifications: action.payload,
        total_count: action.total_count,
        loading: false,
      };

    case GET_SELECT_EDUCATIONAL_QUALIFICATIONS:
      return {
        ...state,
        educational_qualification_select: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSERT_EDUCATIONAL_QUALIFICATION:
      return {
        ...state,
        educational_qualifications: [action.payload].concat(
          state.educational_qualifications,
        ),
        loading: false,
      };
    case UPDATE_EDUCATIONAL_QUALIFICATION:
      return {
        ...state,
        loading: false,
      };
    case DELETE_EDUCATIONAL_QUALIFICATION:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_EDUCATIONAL_QUALIFICATIONS:
      return {
        ...state,
        educational_qualifications: false,
        loading: false,
      };

    /**
     * LANGUAGES
     */

    case GET_SELECT_LANGUAGES:
      return {
        ...state,
        languages_select: action.payload,
        total_count: action.total_count,
        loading: false,
      };

    case MY_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
        total_count: action.total_count,
        loading: false,
      };

    case INSERT_MY_LANGUAGES:
      return {
        ...state,
        my_languages: [action.payload].concat(state.my_languages),
        loading: false,
      };

    case UPDATE_MY_LANGUAGES:
      return {
        ...state,
        loading: false,
      };

    case DELETE_MY_LANGUAGES:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
