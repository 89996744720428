import React, { useState, useEffect } from 'react';

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    const refreshCacheAndReload = (currentVersion) => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (let i = 0; i < names.length; i += 1) {
            const name = names[i];
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      localStorage.setItem('app-version', currentVersion);
      window.location.reload(true);
    };

    useEffect(() => {
      const latestVersion = localStorage.getItem('app-version');
      const currentVersion = process.env.REACT_APP_BUILD_VERSION;

      if (latestVersion !== currentVersion) {
        setIsLatestBuildDate(false);
        refreshCacheAndReload(currentVersion);
      } else {
        setIsLatestBuildDate(true);
      }
    }, []);

    return <>{isLatestBuildDate ? <Component {...props} /> : null}</>;
  }

  return ClearCacheComponent;
}

export default withClearCache;
