import {
  GET_ALL_JOBS,
  GET_SELECT_JOBS,
  INSTANCE_JOB,
  CLEAR_JOBS,
  INSERT_JOB,
  UPDATE_JOB,
  INSERT_MY_JOB,
  DELETE_MY_JOB,
  DELETE_JOB,
  UPDATE_MY_JOB,
  MY_JOBS,
} from '../actionType';

const INIT_STATE = {
  jobs: false,
  jobs_select: false,
  job: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        jobs: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case MY_JOBS:
      return {
        ...state,
        jobs: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case GET_SELECT_JOBS:
      return {
        ...state,
        jobs_select: action.payload,
        loading: false,
      };
    case INSERT_JOB:
      return {
        ...state,
        loading: false,
      };
    case INSERT_MY_JOB:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MY_JOB:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MY_JOB:
      return {
        ...state,
        loading: false,
      };
    case DELETE_JOB:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_JOB:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_JOBS:
      return {
        ...state,
        jobs: false,
        loading: false,
      };
    case INSTANCE_JOB:
      return {
        ...state,
        job: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
