import { persistReducer } from 'redux-persist/lib/index';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/named
import * as routerHelpers from '_helpers';
import { isAuthGuardActive, currentUser } from 'constants/defaultValues';
import { getCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../actions';
import { getUserByToken } from './actions';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
};

const initialAuthState = {
  user: undefined,
  jwt: undefined,
  currentUser: isAuthGuardActive ? currentUser : getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
};

export const reducer = persistReducer(
  {
    storage,
    key: 'valoredevep-sajknsa999072.aslfhjehbfinuwe.2178931u23hdahjsa',
    whitelist: ['user', 'jwt'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { jwt } = action.payload;
        return { jwt, user: undefined };
      }

      case actionTypes.Register: {
        const { jwt } = action.payload;
        return { jwt, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case LOGIN_USER:
        return { ...state, loading: true, error: '' };
      case LOGIN_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          currentUser: action.payload,
          error: '',
        };
      case LOGIN_USER_ERROR:
        return {
          ...state,
          loading: false,
          currentUser: null,
          error: action.payload.message,
        };
      case FORGOT_PASSWORD:
        return { ...state, loading: true, error: '' };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          forgotUserMail: action.payload,
          error: '',
        };
      case FORGOT_PASSWORD_ERROR:
        return {
          ...state,
          loading: false,
          forgotUserMail: '',
          error: action.payload.message,
        };
      case RESET_PASSWORD:
        return { ...state, loading: true, error: '' };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          newPassword: action.payload,
          resetPasswordCode: '',
          error: '',
        };
      case RESET_PASSWORD_ERROR:
        return {
          ...state,
          loading: false,
          newPassword: '',
          resetPasswordCode: '',
          error: action.payload.message,
        };
      case REGISTER_USER:
        return { ...state, loading: true, error: '' };
      case REGISTER_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          currentUser: action.payload,
          error: '',
        };
      case REGISTER_USER_ERROR:
        return {
          ...state,
          loading: false,
          currentUser: null,
          error: action.payload.message,
        };
      case LOGOUT_USER:
        return { ...state, currentUser: null, error: '' };
      default:
        return state;
    }
  },
);

export const actions = {
  login: (jwt) => ({ type: actionTypes.Login, payload: { jwt } }),
  register: (jwt) => ({
    type: actionTypes.Register,
    payload: { jwt },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: result } = yield getUserByToken();
    yield put(actions.fulfillUser(result.result));
  });
}

export default actions;
