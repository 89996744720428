import {
  GET_ALL_SKILLS,
  GET_SELECT_SKILLS,
  INSTANCE_SKILL,
  CLEAR_SKILLS,
  INSERT_SKILL,
  UPDATE_SKILL,
  INSERT_MY_SKILL,
  DELETE_MY_SKILL,
  DELETE_SKILL,
  MY_SKILLS,
} from '../actionType';

const INIT_STATE = {
  skills: false,
  skills_select: false,
  skill: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SKILLS:
      return {
        ...state,
        skills: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case MY_SKILLS:
      return {
        ...state,
        skills: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case GET_SELECT_SKILLS:
      return {
        ...state,
        skills_select: action.payload,
        loading: false,
      };
    case INSERT_SKILL:
      return {
        ...state,
        skills: [action.payload].concat(state.skills),
        loading: false,
      };
    case INSERT_MY_SKILL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MY_SKILL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SKILL:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SKILL:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_SKILLS:
      return {
        ...state,
        skills: false,
        loading: false,
      };
    case INSTANCE_SKILL:
      return {
        ...state,
        skill: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
