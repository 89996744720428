import * as redux from 'redux';
import { all } from 'redux-saga/effects';
import * as auth from './auth/reducer';
import Country from './country/reducer';
import Users from './user/reducer';
import Registry from './registries/reducer';
import Dashboard from './dashboard/reducer';
import Notification from './notification/reducer';
import settings from './settings/reducer';
import menu from './menu/reducer';
import chatApp from './chat/reducer';
import Skills from './skill/reducer';
import Jobs from './job/reducer';
import JobSector from './job_sector/reducer';
import Cities from './city/reducer';
import Region from './region/reducer';
import Education from './education/reducer';
import Statistic from './statistics/reducer';
import Selection from './selection/reducer';

export const reducers = redux.combineReducers({
  auth: auth.reducer,
  dashboard: Dashboard,
  settings,
  menu,
  chatApp,
  notification: Notification,
  registries: Registry,
  user: Users,
  countries: Country,
  skills: Skills,
  jobs: Jobs,
  cities: Cities,
  jobSector: JobSector,
  region: Region,
  education: Education,
  statistic: Statistic,
  selection: Selection,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
