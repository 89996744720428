import { GET_MASTERSTAT } from '../actionType';

const INIT_STATE = {
  stats: false,
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MASTERSTAT:
      return {
        ...state,
        stats: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
